import React from 'react';

import Modal from '@components/modal/modal.component';
import ProductModalContent, { FooterButtonComponent } from '@components/product-modal/product-modal-content';

const ProductModalComponent = (
  {
    product,
  },
) => (
  <Modal
    footer={<FooterButtonComponent product={product} />}
  >
    <ProductModalContent product={product} />
  </Modal>
);

export default ProductModalComponent;
