import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Theme, { deviceMediaQ } from '@src/theme';

import Layout from '@components/layout/layout.component';
import PageContainer from '@components/page-container/page-container.component';
import ProductCardComponent from '@components/product-card/product-card.component';

import { getProductsUrlByType } from '@src/utils/product.utils';

const ProductFilter = styled.div`
  display: flex;
  width: 100%;
  gap: 5%;
  justify-content: center;
  margin-bottom: 35px;

  button {
    background: inherit;
    border: none;
    color: #868686;
    font-weight: 400;
    text-decoration: none;
    line-height: 24px;
    font-size: 20px;
    letter-spacing: 0.5px;
    position: relative;
    padding: 4px;

    &.primary {
      color: ${Theme.secondaryColor};
      font-weight: bold;
    }
    
    @media ${deviceMediaQ.mobile} {
      font-size: 15px;
    }
  }
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  grid-gap: 39px 29px;
  
  @media ${deviceMediaQ.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media ${deviceMediaQ.tablet} {
    grid-template-columns: 1fr;
  }
`;

const ProductsPage = ({ location }) => {
  const [selectedProducts, setSelectedProducts] = React.useState({ list: [], type: 'rescue' });

  const { allProductsJson: { nodes: products } } = useStaticQuery(graphql`
      query {
          allProductsJson {
              nodes {
                  slug
                  type
                  title
                  images
                  features
                  accessories
                  datasheet_url
                  doc_url
                  dimensions_list
                  dimensions {
                      l
                      a
                      p
                  }
              }
          }
      }
  `);

  const orderedProducts = React.useMemo(
    () => products.sort((a, b) => (a.title > b.title ? 1 : -1)),
    [products],
  );

  const resonanceProducts = React.useMemo(
    () => orderedProducts.filter((p) => p.type === 'resonance'),
    [orderedProducts],
  );

  const rescueProducts = React.useMemo(
    () => orderedProducts.filter((p) => p.type === 'rescue'),
    [orderedProducts],
  );

  const setProducts = (type) => {
    let productsList = type === 'rescue' ? rescueProducts : resonanceProducts;
    setSelectedProducts({ list: productsList, type });
    window.history.replaceState({}, '', getProductsUrlByType(type));
  };

  React.useEffect(() => {
    setProducts(
      location.pathname.includes('ressonancia') ? 'resonance' : 'rescue'
    );
  }, [location.pathname]);


  return (
    <Layout title='Produtos' location={location}>
      <StaticImage
        loading='eager'
        src='../images/bg03.jpeg'
        alt=''
        layout='fullWidth'
        className='header-media hide-mobile'
      />

      <PageContainer>

        <h2 className='subtitle' style={{ margin: '32px 0' }}>Produtos</h2>

        <ProductFilter>
          <button
            className={`hoverable bluish caption ${selectedProducts.type === 'rescue' ? 'primary' : ''}`}
            onClick={() => setProducts('rescue')}
          >
            RESGATE
          </button>
          <button
            className={`hoverable bluish caption ${selectedProducts.type === 'resonance' ? 'primary' : ''}`}
            onClick={() => setProducts('resonance')}
          >
            RESSONÂNCIA MAGNÉTICA
          </button>
        </ProductFilter>

        <ProductsContainer>
          {selectedProducts.list.map((product) => (
            <ProductCardComponent product={product} key={product.slug} />
          ))}
        </ProductsContainer>
      </PageContainer>
    </Layout>
  );
};

export default ProductsPage;
