import React from 'react';
import ReactDOM from 'react-dom';

import ModalStyles from './modal.styles';
import { useModal } from '@src/store/modal/modal-context';
import { closeModal } from '@src/store/modal/modal.actions';

const ModalComponent = ({ children, footer = null, header = null }) => {
  const { state, dispatch } = useModal();

  const modalRoot = React.useMemo(
    () => document.getElementById('pmod'),
    [],
  );

  const onKeyPress = ({ key }) => {
    if (key === 'Escape') {
      dispatch(closeModal());
    }
  };

  React.useEffect(() => {
    const onClick = ({ target: { tagName } }) => {
      // clicked outside the modal
      if (tagName === 'DIALOG') {
        dispatch(closeModal());
      }
    };

    window.addEventListener('click', onClick);
    window.addEventListener('keyup', onKeyPress);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('click', onClick);
      window.removeEventListener('keyup', onKeyPress);
      document.body.style.overflow = null;
    };
  }, []);

  return ReactDOM.createPortal(
    <ModalStyles className={state.open ? 'open' : ''}>
      <div className='content'>
        <div className='header'>
          {header}
          <span
            role="button"
            className='close'
            onClick={() => dispatch(closeModal())}
            tabIndex={0}
          >
            &times;
          </span>
        </div>
        <div className='body'>
          {children}
        </div>
        <div className='footer'>
          {footer}
        </div>
      </div>
    </ModalStyles>,
    modalRoot,
  );
};

export default ModalComponent;
