import Theme from '@src/theme';
import styled from 'styled-components';

export default styled.dialog`
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  border: none;
  display: none;
  align-items: center;
  justify-content: center;
  animation: fade-in 150ms ease-out;
  
  &.open {
    display: flex;
  }

  .content {
    position: relative;
    width: 50vw;
    background: white;
    border-radius: ${Theme.borderRadius};
    transition: all 0.3s ease-out;
    box-sizing: border-box;
    z-index: 501;

    .header {
      height: 49px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      padding: 0 20px;
      flex-direction: row-reverse;
    }
    
    .body {
      padding: 30px 50px;
      overflow: auto;
      height: 70vh;
    }
    
    .footer {
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      gap: 15px;
    }
  }
  
  .close {
    color: ${Theme.primaryColor};
    font-size: 2rem;
    
    &:hover {
      cursor: pointer;
    }
  }
`;
