import Theme, { deviceMediaQ } from '@src/theme';
import styled from 'styled-components';

const ProductCardStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 350px;

  a {
    color: ${Theme.primaryColor};
  }
  
  @media ${deviceMediaQ.tablet} {
    height: 35vh;
    gap: 5px;
  }

  h4 {
    margin-top: 22px;
    margin-bottom: 22px;
    color: ${Theme.darkColor};
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-weight: normal;

    @media ${deviceMediaQ.tablet} {
      font-size: 16px;
    }
  }
  
  .image {
    height: 100%;
    width: 100%;

  }

  img {
    text-align: center;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .button {
      margin-bottom: 5px;
      width: 100%;
      max-width: 150px;
      
      @media ${deviceMediaQ.tablet} {
        max-width: unset;
      }
    }

    @media ${deviceMediaQ.tablet} {
      gap: 7px;
    }
  }
  
  .bottom {
    margin-top: auto;
    
    @media ${deviceMediaQ.tablet} {
      h4 {
        flex: 1;
      }
    }
  }

  .v-line {
    width: 0.5px;
    background-color: #CCCCCC;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default ProductCardStyles;
