import React from 'react';
import { navigate, Link } from 'gatsby';
import { mediaSize } from '@src/theme';

import Button from '@components/button/button.component';
import ProductImage from '@components/product-image/product-image.component';
import ProductModal from '@components/product-modal/product-modal.component';
import Card from '@components/card/card.component';

import DownloadIcon from '@icons/download_.svg';

import ProductCardStyled from './product-card.styles';

import { useModal } from '@src/store/modal/modal-context';
import { openModal } from '@src/store/modal/modal.actions';

import { getProductUrl } from '@src/utils/product.utils';

const ProductCardComponent = ({ product }) => {
  const { state, dispatch } = useModal();
  const [productSelected, setProductSelected] = React.useState(false);
  const isMobile = window.matchMedia(`(max-width: ${mediaSize.tablet})`).matches;

  React.useEffect(() => {
    if (!state.open) {
      setProductSelected(false);
    }
  }, [state.open]);

  return (
    <>
      {
        productSelected ? (
          <ProductModal product={product} />
        ) : null
      }
      <Card padding='16px 24px'>
        <ProductCardStyled>
          <ProductImage product={product} objectFit='contain' className='image' />
          <div className='show-tablet v-line' />

          <div className='bottom'>
            <h4>{product.title}</h4>
            <div className='buttons'>
              <a
                href={product.doc_url}
                download={`detalhes-${product.slug}`}
                target='_blank'
              >
                <Button primary icon={<DownloadIcon />}>Detalhes</Button>
              </a>
              {
                isMobile ? (
                  <Button>
                    <Link to={getProductUrl(product)} className='btn'>Especificações</Link>
                  </Button>
                ) : (
                  <Button onClick={() => {
                    setProductSelected(true);
                    dispatch(openModal());
                  }}>
                    Especificações
                  </Button>
                )
              }
            </div>
          </div>
        </ProductCardStyled>
      </Card>
    </>
  );
};

export default ProductCardComponent;
